<template>
    <b-row class="align-items-center justify-content-center">
        <div class="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5">
            <h1 class="display-4 text-center mb-3 text-capitalize">
                {{ $t("auth.register") }}
            </h1>
            <p class="text-muted text-center mb-5">{{ $t("auth.zesec") }}</p>
            <div v-if="registerType == 0" class="row mb-2">
                <div class="col-12">
                    <p class="text-center mb-5">
                        {{ $t("auth.select_register") }}
                    </p>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 text-center">
                    <b-button
                        variant="outline-dark"
                        class="w-80"
                        @click="registerType = 1"
                        >{{ $t("auth.register_type_user") }}</b-button
                    >
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 text-center">
                    <b-button
                        variant="outline-dark"
                        class="w-80"
                        @click="registerType = 2"
                        >{{ $t("auth.register_type_organization") }}</b-button
                    >
                </div>
            </div>

            <b-form @submit.stop.prevent="register" v-if="registerType == 1">
                <div class="form-row">
                    <div class="col-12 col-md-6 mb-3">
                        <label for="FirstName">{{
                            $t("users.first_name")
                        }}</label>
                        <b-form-input
                            id="FirstName"
                            class="input-field"
                            v-model="$v.form.FirstName.$model"
                            :placeholder="$t('users.first_name')"
                            :state="validateState('FirstName')"
                        />
                        <b-form-invalid-feedback id="FirstName-feedback">
                            <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                            {{
                                $t("errors.validation_length_new", { length: 3 })
                            }}
                        </b-form-invalid-feedback>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <label for="LastName">{{
                            $t("users.last_name")
                        }}</label>
                        <b-form-input
                            id="LastName"
                            class="input-field"
                            v-model="form.LastName"
                            :placeholder="$t('users.last_name')"
                        />
                    </div>
                </div>

                <b-form-group label="Email">
                    <b-form-input
                        id="Email"
                        class="input-field"
                        v-model="$v.form.Email.$model"
                        placeholder="Email"
                        :state="validateState('Email')"
                    />
                    <b-form-invalid-feedback id="Email-feedback">
                        <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                        {{
                            $t("errors.validation_email")
                        }}
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group :label="$t('phoneInput.phoneNumberLabel')">
                    <vue-phone-number-input
                        id="Phonenumber"
                        class="input-field"
                        :translations="$t('phoneInput')"
                        v-model="$v.form.Phonenumber.$model"
                        :error="!validPhone && submitted"
                        @update="validatePhone"
                        fetch-country
                        type="text"
                    />
                    <span id="phoneNumber-feedback" v-if="!validPhone && submitted" style="color: #e63857;  font-size: 0.8125rem">
                        <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                        {{ $t("errors.validation_phone_number") }}
                    </span>
                </b-form-group>
                <b-form-group :label="$t('auth.password')">
                    <b-input-group>
                        <b-form-input
                            id="Password"
                            class="input-field"
                            :placeholder="$t('auth.password')"
                            :type="showPassword ? 'text' : 'password'"
                            v-model="$v.form.Password.$model"
                            :state="validateState('Password')"
                        />
                        <b-input-group-append>
                            <b-button type="button" @click="showPassword = !showPassword">
                                <span class="icon is-small is-right"><b-icon :icon="showPassword ? 'eye-fill' : 'eye-slash-fill'"/></span>
                            </b-button>
                        </b-input-group-append>
                        <b-form-invalid-feedback id="Password-feedback-len" v-if="!$v.form.Password.minLength || !$v.form.Password.maxLength">
                            <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                            {{ $t("errors.validation_password_length") }}
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback id="Password-feedback" v-else>
                            <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                            {{ $t("errors.validation_password") }}
                        </b-form-invalid-feedback>
                    </b-input-group>
                </b-form-group>
                <b-form-group :label="$t('auth.confirm_password')">
                    <b-input-group>
                        <b-form-input
                            id="passwordConfirm"
                            class="input-field"
                            :placeholder="$t('auth.confirm_password')"
                            :type="showPassword ? 'text' : 'password'"
                            v-model="$v.form.passwordConfirm.$model"
                            :state="validateState('passwordConfirm')"
                        />
                        <b-input-group-append>
                            <b-button type="button" @click="showPassword = !showPassword">
                                <span class="icon is-small is-right"><b-icon :icon="showPassword ? 'eye-fill' : 'eye-slash-fill'"/></span>
                            </b-button>
                        </b-input-group-append>
                        <b-form-invalid-feedback id="Password-feedback-len" v-if="!$v.form.passwordConfirm.minLength || !$v.form.passwordConfirm.maxLength">
                            <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                            {{ $t("errors.validation_password_length") }}
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback id="passwordConfirm-feedback" v-else>
                            <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                            {{ $t("errors.validation_password_mismatch") }}
                        </b-form-invalid-feedback>
                    </b-input-group>
                </b-form-group>
                <b-button
                    size="lg"
                    class="mb-3"
                    block
                    variant="primary"
                    type="submit"
                    >{{ $t("buttons.register") }}</b-button
                >

                <b-row class="mt-3">
                    <b-col v-if="getAuthErrors">
                        <b-alert show variant="light">
                            <b-icon
                                icon="exclamation-circle"
                                variant="danger"
                            ></b-icon>
                            {{ getAuthErrors }}
                        </b-alert>
                    </b-col>
                </b-row>
            </b-form>

            <b-form @submit.stop.prevent="register" v-if="registerType == 2">
                <div class="form-row">
                    <div class="col-12 col-md-6 mb-3">
                        <label for="FirstName">{{
                            $t("users.first_name")
                        }}</label>
                        <b-form-input
                            id="FirstName"
                            class="input-field"
                            v-model="$v.form.FirstName.$model"
                            :placeholder="$t('users.first_name')"
                            :state="validateState('FirstName')"
                        />
                        <b-form-invalid-feedback id="FirstName-feedback">
                            <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                            {{
                                $t("errors.validation_length", { length: 3 })
                            }}
                        </b-form-invalid-feedback>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <label for="LastName">{{
                            $t("users.last_name")
                        }}</label>
                        <b-form-input
                            id="LastName"
                            class="input-field"
                            v-model="form.LastName"
                            :placeholder="$t('users.last_name')"
                        />
                    </div>
                </div>

                <b-form-group label="Email">
                    <b-form-input
                        id="Email"
                        class="input-field"
                        v-model="$v.form.Email.$model"
                        placeholder="Email"
                        :state="validateState('Email')"
                    />
                    <b-form-invalid-feedback id="Email-feedback">
                        <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                        {{
                            $t("errors.validation_email")
                        }}
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group :label="$t('phoneInput.phoneNumberLabel')">
                    <vue-phone-number-input
                        id="Phonenumber"
                        class="input-field"
                        :translations="$t('phoneInput')"
                        v-model="$v.form.Phonenumber.$model"
                        :error="!validPhone && submitted"
                        @update="validatePhone"
                        fetch-country
                        type="text"
                    />
                    <span id="phoneNumber-feedback" v-if="!validPhone && submitted" style="color: #e63857;  font-size: 0.8125rem">
                        <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                        {{ $t("errors.validation_phone_number") }}
                    </span>
                </b-form-group>
                <b-form-group :label="$t('auth.password')">
                    <b-input-group>
                        <b-form-input
                            id="Password"
                            class="input-field"
                            :placeholder="$t('auth.password')"
                            :type="showPassword ? 'text' : 'password'"
                            v-model="$v.form.Password.$model"
                            :state="validateState('Password')"
                        />
                        <b-input-group-append>
                            <b-button type="button" @click="showPassword = !showPassword">
                                <span class="icon is-small is-right"><b-icon :icon="showPassword ? 'eye-fill' : 'eye-slash-fill'"/></span>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                    <b-form-invalid-feedback id="Password-feedback">
                        <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                        {{ $t("errors.validation_password") }}
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group :label="$t('auth.confirm_password')">
                    <b-input-group>
                        <b-form-input
                            id="passwordConfirm"
                            class="input-field"
                            :placeholder="$t('auth.confirm_password')"
                            :type="showPassword ? 'text' : 'password'"
                            v-model="$v.form.passwordConfirm.$model"
                            :state="validateState('passwordConfirm')"
                        />
                        <b-input-group-append>
                            <b-button type="button" @click="showPassword = !showPassword">
                                <span class="icon is-small is-right"><b-icon :icon="showPassword ? 'eye-fill' : 'eye-slash-fill'"/></span>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                    <b-form-invalid-feedback id="passwordConfirm-feedback">
                        <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                        {{ $t("errors.validation_password_mismatch") }}
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-button
                    size="lg"
                    class="mb-3"
                    block
                    variant="primary"
                    type="submit"
                    >{{ $t("buttons.register") }}</b-button
                >

                <b-row class="mt-3">
                    <b-col v-if="getAuthErrors">
                        <b-alert show variant="light">
                            <b-icon
                                icon="exclamation-circle"
                                variant="danger"
                            ></b-icon>
                            {{ getAuthErrors }}
                        </b-alert>
                    </b-col>
                </b-row>
            </b-form>
            <div class="text-center mt-3">
                <small class="text-muted text-center">
                    {{ $t("auth.registered") }}
                    <b-link :to="{ name: 'login' }">
                        {{ $t("auth.login") }}
                    </b-link>
                </small>
            </div>
            <language-switcher class="p-0 w-100 mt-3" />
        </div>
        <div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
            <div class="bg-cover vh-100 mt-n1 mr-n3 auth-bg"></div>
        </div>
    </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import { AUTH_REGISTER, AUTH_ERROR_CLEAR, REGISTER_USER } from "@/store/types/auth";
import { INIT_STARTED, INIT_COMPLETED } from "@/store/types/global";

import { validationMixin } from "vuelidate";
import { required, email, minLength, sameAs, maxLength } from "vuelidate/lib/validators";
import VuePhoneNumberInput from "vue-phone-number-input";
import LanguageSwitcher from "@/components/common/LanguageSwitcher";
const stringEntropy = require("fast-password-entropy");

export default {
    mixins: [validationMixin],
    computed: {
        ...mapGetters(["getAuthErrors"]),
    },
    data() {
        return {
            form: {
                FirstName: "",
                LastName: "",
                Email: "",
                Phonenumber: "",
                Password: "",
                passwordConfirm: "",
            },
            validPhone: false,
            fullPhone: null,
            submitted: false,
            registerType: 1,
            showPassword: false,
        };
    },
    validations: {
        form: {
            FirstName: {
                required,
                minLength: minLength(3),
            },
            Email: {
                required,
                email,
            },
            Phonenumber: {
                required,
                phone: function () {
                    return this.validPhone;
                },
            },
            Password: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(20),
                entropy: function () {
                    return stringEntropy(this.form.Password) >= 50;
                },
            },
            passwordConfirm: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(20),
                sameAs: sameAs("Password"),
            },
        },
    },
    beforeCreate() {
        this.$nextTick().then(() =>
            document.body.classList.add(
                "d-flex",
                "align-items-center",
                "bg-auth",
                "border-top",
                "border-top-2",
                "border-primary"
            )
        );
    },
    mounted() {
        this.$store.commit(INIT_COMPLETED);
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty && this.submitted ? !$error : null;
        },
        validatePhone(phone) {
            this.validPhone = phone.isValid;
            this.fullPhone = phone;
        },
        register() {
            this.submitted = true;
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

            let payload = {
                ...this.form,
                ...{ Phonenumber: this.fullPhone.formattedNumber },
            };
            this.$store
                .dispatch(AUTH_REGISTER, payload)
                .then((response) => {
                    /* this.$store.dispatch(REGISTER_USER, payload).then((response) => {
                        if(response.status == true){
                            this.$router.push({ name: "dashboard" });
                        }else{
                            this.$router.push({ name: "login" });
                        }
                    }); */

                    /*this.$intercom.boot({
                        user_id: response.User.Id,
                        name: `${response.FirstName} ${
                            response.User.LastName || ""
                        }`,
                        email: response.User.Email,
                        avatar: {
                            type: "avatar",
                            image_url: response.User.SmallImageUrl,
                        },
                        language_override: this.$i18n.locale,
                    });*/

                    document.body.classList.remove(
                        "d-flex",
                        "align-items-center",
                        "bg-auth",
                        "border-top",
                        "border-top-2",
                        "border-primary"
                    );

                    this.$router.push({ name: "dashboard" });
                })
                .catch((error) => {});
                const delayVal = setTimeout(() => {
                    this.$store.dispatch(REGISTER_USER, payload).then((response) => {
                        if(response.status == true){
                            document.body.classList.remove(
                                "d-flex",
                                "align-items-center",
                                "bg-auth",
                                "border-top",
                                "border-top-2",
                                "border-primary"
                            );
                            this.$router.push({ name: "dashboard" });
                        }
                    });
                 }, 4000);
                 /* else{
                            this.$router.push({ name: "login" });
                        } */
        },
    },
    components: {
        "vue-phone-number-input": VuePhoneNumberInput,
        "language-switcher": LanguageSwitcher,
    },
    destroyed() {
        this.$store.commit(AUTH_ERROR_CLEAR);
    },
};
</script>
<style scoped>
.w-80 {
    width: 80%;
}
</style>
